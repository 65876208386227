.IntroBottom {
  width: 1280px;
  margin : 10rem auto 0 auto;

  .intro-bottom-contents-wrap {
    font-size: 20px;
    font-weight: 600;

    hr{
      height: 1px;
      background: rgba(0, 0, 0, 0.2);
      border: 0;
    }

    .intro-bottom-cotents-package {
      margin-top: 3rem;
      margin-bottom: 3rem;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .intro-writing{
        text-align: center;
        p{
          margin-top: 30px;
        }
        .bottom{
          font-weight: 300;
          font-size: 16px;
        }

        .middle{
          font-size: 20px;
          border-radius: 15px;
          align-items: center;
          height: 50px;
          border: 1px solid #636363;
          width: 500px;
          display: flex;
          justify-content: center;
        }

        .middle-us{
          font-size: 20px;
          border-radius: 15px;
          align-items: center;
          height: 50px;
          border: 1px solid #636363;
          width: 500px;
          display: flex;
          justify-content: center;
          margin-left : 50px;
        }
        .bottom-us{
          color: #636363;
          line-height: 0px;
          font-weight: 500;
          margin-top: 30px;
        }
      }
    }

    .intro-bottom-contents-video{
      margin-top: 3rem;
      margin-bottom: 3rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      .intro-writing{
        text-align: center;
        font-size: 20px;
        font-weight: 600;
      }

      .intro-writing-us{
        text-align: center;
        font-size: 20px;
        font-weight: 500;
      }

      .intro-button-us{
        button{
          cursor:pointer;
        font-weight: 500;
        width: 284px;
        font-size: 20px;
        color: #ffffff;
        height: 61px;
        border: none;
        border-radius: 10px;
        background-color: #FFCC00;
        }
      }

      .intro-button{
        button{
          cursor:pointer;
          font-weight: 600;
          width: 284px;
          font-size: 20px;
          color: #ffffff;
          height: 61px;
          border: none;
          border-radius: 10px;
          background-color: #FFCC00;
        }
      }
    }

    .intro-bottom-contents-device{
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .intro-writing{
        margin-bottom:2rem;
      }

      .intro-writing-us{
        font-weight: 500;
        margin-bottom:2rem;
      }


      .intro-image{
        margin-bottom: 7rem;
        align-items: center;
        height: 200px;
        width: 750px;
        border: 1px solid #FCC500;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        border-radius: 34px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .IntroBottom {
    .intro-bottom-title-wrap {
      p {
        padding: 0 2rem;
      }
    }
    .intro-bottom-contents-wrap {
      .intro-content-list {
        .intro-content-item {
          width: calc(50% - 2rem);
          margin: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .IntroBottom {
    .intro-bottom-title-wrap {
      h2 {
        font-size: 28px;
        word-break: keep-all;
      }
      p {
        font-size: 18px;
        padding: 0 0.5rem;
      }
    }
    .intro-bottom-contents-wrap {
      .intro-content-list {
        .intro-content-item {
          width: calc(100% - 1rem);
          margin: 0.5rem;
        }
      }
    }
  }
}
