.select {
    position: relative;
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 15px;
    width: 100%;

    select {
        display: inline-block;
        width: 100%;
        cursor: pointer;
        padding: 20px 14px 20px;
        padding-left: 32.75862068965517%;
        outline: 0;
        border: 1px solid #bbbbbb;
        background: #ffffff;
        color: #333333;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 16px;
    }

    label {
        position: absolute;
        font-size: 16px;
        font-weight: 500;
        top: 18px;
        left: 20px;
    }
}

.select+.select {
    margin-top: 0px;
    margin-bottom: 35px
}



.select select::-ms-expand {
    display: none;
}

.select select:hover,
.select select:focus {
    color: #333333;
    background: #ffffff;
}

.select select:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.select__arrow {
    position: absolute;
    top: 26px;
    right: 15px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 12px 8px 0 8px;
    border-color: #2fae8e transparent transparent transparent;
}

.select select:hover~.select__arrow,
.select select:focus~.select__arrow {
    border-top-color: #2fae8e;
}

.select select:disabled~.select__arrow {
    border-top-color: #ffffff;
}


@media all and (max-width:768px) {
    .select {
        select {
            font-size: 14px;
        }

        label {
            font-size: 14px;
            top: 20px;
        }
    }
}

@media all and (max-width:500px) {
    .select {
        select {
            font-size: 12px;
        }

        label {
            font-size: 12px;
        }
    }
}