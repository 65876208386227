.LectureVideoList {
  max-width: 1180px;
  margin: 0 auto;
  margin-bottom: 4rem;
  margin-top: 2rem;

  .lecture-subject {
    margin-top: 4rem;
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.5rem;
  }

  .lecture-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
  }
}
