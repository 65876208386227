.CreateOrJoinPopup {
    width: 600px;

    .select-create-join-title {
        width: 100%;
        background: #FCC500;
        color: #ffffff;
        text-align: left;
        padding: 0 20px;
        font-size: 22px;
        line-height: 60px;
        border-radius: 10px 10px 0px 0px;
        display: flex;

        .select-create-join-close {
            margin-left: auto;
            cursor: pointer;

            svg {
                vertical-align: middle;
                font-size: 4rem;
            }
        }
    }

    .select-create-join-info {
        background: #ffffff;
        padding: 5rem 2rem;
        border-radius: 0px 0px 10px 10px;

        .select-create-join-button-box {

            .select-create-join-button {
                width: 26.66666666666667%;
                background: #f3ab19;
                height: 55px;
                font-size: 22px;
                font-weight: 500;
                border: none;
                border-radius: 27.5px;
                color: #ffffff;
                cursor: pointer;
                margin: 0 15px;

                &:hover {
                    background: #e7960a;
                }
            }
        }
    }
}

@media all and (max-width:768px) {
    .CreateOrJoinPopup {
        width: 100%;

        .select-create-join-box {
            .select-create-join-info {
                .select-create-join-button-box {
                    display: flex;

                    .select-create-join-button {
                        font-size: 1rem;
                        height: 40px;
                        width: 35%;
                    }
                }
            }
        }
    }
}