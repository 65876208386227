.HeaderRight {
  float: right;

  li {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 0;

    .login-button {
      width: 100px;
      line-height: 35px;
      border: none;
      background: #ffffff;
      color: #FCC500;
      border-radius: 17.5px;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      outline: none;
    }

    .login-button:hover {
      background: rgba(245, 245, 245, 0.8);
      color: #FCC500;
    }


    .login-button-ar {
      width: 150px;
      line-height: 35px;
      border: none;
      background: #ffffff;
      color: #FCC500;
      border-radius: 17.5px;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      outline: none;
    }

    .login-button-ar:hover {
      background: rgba(245, 245, 245, 0.8);
      color: #FCC500;
    }
  }
}
