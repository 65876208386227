.ClassJoinPopup {
    width: 600px;

    .class-join-title {
        width: 100%;
        background: #FCC500;
        color: #ffffff;
        text-align: left;
        padding: 0 20px;
        font-size: 22px;
        line-height: 60px;
        border-radius: 10px 10px 0px 0px;
    }

    .class-join-info {
        background: #ffffff;
        border-radius: 0px 0px 10px 10px;
        padding: 0 1rem;

        .class-join-text {
            font-size: 18px;
            color: #737373;
            padding-top: 8.333333333333333%;
            padding-bottom: 5%;
        }

        .class-join-input-box {
            width: 50%;
            margin: 0 auto;

            .effect-7 {
                padding: 16px 14px 20px;
                padding-left: 8.758621%;
            }

            .notify {
                left: 9%;
                top: 39px;
            }


            .class-code-input {
                width: 41.66666666666667%;
                font-size: 16px;
                border: 1px solid #999999;
                padding-left: 3%;
                color: #333333;
            }
        }

        .class-join-button-box {
            padding-top: 6.666666666666667%;
            padding-bottom: 6.666666666666667%;

            .class-join-button {
                width: 26.66666666666667%;
                background: #f3ab19;
                height: 55px;
                font-size: 22px;
                font-weight: 500;
                border: none;
                border-radius: 27.5px;
                color: #ffffff;
                cursor: pointer;
                margin: 0 15px;

                &:hover {
                    background: #e7960a;
                }
            }

            .class-join-cancel-button {
                width: 26.66666666666667%;
                background: #999999;
                height: 55px;
                font-size: 22px;
                font-weight: 500;
                border: none;
                border-radius: 27.5px;
                color: #ffffff;
                cursor: pointer;
                margin: 0 15px;


                &:hover {
                    background: #888888;
                }
            }
        }
    }
}

@media all and (max-width:768px) {
    .ClassJoinPopup {
        width: 100%;

        .class-join-info {
            .class-join-text {
                font-size: .9rem;
            }

            .class-join-button-box {
                .class-join-button {
                    font-size: 1rem;
                    height: 40px;
                    width: 35%;
                }

                .class-join-cancel-button {
                    font-size: 1rem;
                    height: 40px;
                    width: 35%;
                }
            }
        }
    }


}