.MobileOrderItem {
  width: 100%;
  padding: 0 1rem;
  margin: 1.5rem 0;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  .order-top {
    display: flex;
    align-items: baseline;
    padding-top: 1.5rem;
    .order-product-name {
      font-size: 2rem;
    }
    .order-seq {
      margin-left: 1rem;
      .order-detail-link {
        color: #333;
        span {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .order-detail {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    .order-thumbnail {
      flex: 1;
      img {
        max-width: 100%;
      }
    }

    .order-info {
      flex: 1;
      text-align: left;
    }
  }
}

@media all and (max-width: 768px) {
  .MobileOrderItem {
    .order-top {
      flex-direction: column-reverse;
      padding-top: 1rem;
      .order-product-name {
        font-size: 1.25rem;
      }
      .order-seq {
        margin-left: 0;
      }
    }
  }
}
