.IntroTop {
  margin: 4rem 0;
  .title-wrap {
    text-align: center;
    margin: 8rem 0;

    h2 {
      font-size: 33px;
      font-weight: 800;
      color: #000;
    }
    p {
      margin-top: 2rem;
      font-size: 20px;
      font-weight: 400;
      color: #636363;
      line-height: 0px;
    }

    .title-button{
      margin: 6rem 0;

      button{
        background-color: #ffcc00;
        border-radius: 10px;
        width: 185px;
        height: 60px;
        font-size: 20px;
        border: none;
        font-weight: 600;
        color: #ffffff;
        cursor:pointer;
      }
    }
    .title-img-box {
      margin: 6rem 0;

      img {
        max-width: 100%;
      }
    }
  }

  .desc-wrap {
    left: -70px;
    height: 60%;
    position: absolute;
    width: 212vh;
    background-color: #F7F7F7;
    margin: -9em 0;

    .desc-list {
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: flex-start;
      .desc-item {
        text-align: center;
        margin: 5rem;

        .desc-img-box {
          img {
            max-width: 100%;
          }
        }

        h4 {
          font-size: 24px;
          font-weight: 800;
          margin-top: 1rem;
          word-break: keep-all;
          min-height: 4rem;
        }

        p {
          margin-top: 1rem;
          font-size: 22px;
          font-weight: 400;
          color: #494645;
          word-break: keep-all;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .IntroTop {
    .title-wrap {
      p {
        padding: 0 2rem;
      }
    }
    .desc-wrap {
      .desc-list {
        flex-wrap: wrap;
        .desc-item {
          margin: 3rem 1rem;
          h4 {
            min-height: 2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .IntroTop {
    .title-wrap {
      h2 {
        font-size: 28px;
        word-break: keep-all;
      }
      p {
        font-size: 18px;
        padding: 0 0.5rem;
      }
    }
  }
}
