.ClassCreatePopup {
    width: 600px;

    .class-create-title {
        width: 100%;
        background: #FCC500;
        color: #ffffff;
        text-align: left;
        padding: 0 20px;
        font-size: 22px;
        line-height: 60px;
        border-radius: 10px 10px 0px 0px;
    }

    .class-create-info {
        padding: 20px;
        background: #ffffff;
        border-radius: 0px 0px 10px 10px;

        .class-create-input-box {
            .input-class-explain {
                width: 100%;
                padding: 20px;
                font-size: 16px;
                color: #333333;
            }
        }

        .class-create-button-box {
            margin: 30px 0;


            .class-create-button {
                width: 26.66666666666667%;
                background: #f3ab19;
                height: 55px;
                font-size: 22px;
                font-weight: 500;
                border: none;
                border-radius: 27.5px;
                color: #ffffff;
                cursor: pointer;
                margin: 0 15px;

                &:hover {
                    background: #e7960a;
                }
            }

            .class-create-cancel-button {
                width: 26.66666666666667%;
                background: #999999;
                height: 55px;
                font-size: 22px;
                font-weight: 500;
                border: none;
                border-radius: 27.5px;
                color: #ffffff;
                cursor: pointer;
                margin: 0 15px;


                &:hover {
                    background: #888888;
                }
            }
        }
    }
}

@media all and (max-width:768px) {
    .ClassCreatePopup {
        width: 100%;

        .class-create-info {
            .class-create-button-box {
                .class-create-button {
                    font-size: 1rem;
                    height: 40px;
                    width: 35%;
                }

                .class-create-cancel-button {
                    font-size: 1rem;
                    height: 40px;
                    width: 35%;
                }
            }
        }
    }
}