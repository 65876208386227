.StudentList {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
}

.class-list-box {
    // text-align: center;
    // font-size: 20px;
    padding-top: 8%;
    padding-bottom: 3%;
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: #333333;
    font-size: 2rem;
    font-weight: bold;
    margin: 8px 0px;
    color: #FCC500;
    font-size: 1.6rem;
    font-weight: 500;
}

.class-list-box::before,
.class-list-box::after {
    content: "";
    flex-grow: 1;
    background: #FCC500;
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 16px;
}

@media all and (max-width :768px) {
    .class-list-box {
        font-size: 1.3rem;
    }
}