.LectureDetailPopup {
  display: none;
  position: fixed;
  overflow: auto;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 0;
  line-height: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-transition: background-color 0.5s linear;
  transition: background-color 0.5s linear;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: opacity;
  z-index: 10000;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  .popup-inner {
    width: 88%;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: none;
    z-index: 1001;
    text-align: left;
    background: #fff;
    border: none;
    -webkit-box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2);
    -webkit-transform-origin: 50% 25%;
    transform-origin: 50% 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 0.28571429rem;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    will-change: top, left, margin, transform, opacity;
    margin: 3rem auto;
    color: #333;

    .title {
      margin-top: 1.5rem;
      font-size: 1.25rem;
      font-weight: 400;
      text-align: center;
      padding: 0rem 1rem 1rem;
      word-break: keep-all;
    }

    .close-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 2rem;
      svg {
        cursor: pointer;
      }
    }
    .youtube {
      margin-top: 1rem;
      padding: 0 1rem;
      iframe {
        width: 100%;
        height: 200px;
        border: none;
      }
    }
    .images {
      margin-top: 1rem;
      padding: 0 1rem;
      .image {
        width: 100%;
        padding-bottom: 0.875rem;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .explain {
      margin-top: 0.5rem;
      font-size: 1rem;
      padding: 0 1rem;
      line-height: 24px;

      word-break: keep-all;
    }

    .actions {
      margin-top: 2rem;
      button {
        width: 100%;
        border: none;
        padding: 1.25rem;
        background-color: #B56AFF;
        font-size: 1.25rem;
        color: #fff;
        cursor: pointer;
        border-radius: 0 0 0.28571429rem 0.28571429rem;
      }
    }
  }
}

.active {
  display: block;
  opacity: 1;
  .popup-inner {
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .LectureDetailPopup {
    .popup-inner {
      width: 750px;
      .youtube {
        iframe {
          height: 300px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .LectureDetailPopup {
    .popup-inner {
      width: 800px;

      .youtube {
        iframe {
          height: 400px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .LectureDetailPopup {
    .popup-inner {
      width: 850px;

      .youtube {
        iframe {
          height: 450px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .LectureDetailPopup {
    .popup-inner {
      width: 900px;

      .youtube {
        iframe {
          height: 500px;
        }
      }
    }
  }
}
