.input-effect-border-box {
    position: relative;

    .input-label {
        position: absolute;
        font-size: 16px;
        font-weight: 500;
        top: 18px;
        left: 20px;
    }

    .before-label {
        position: absolute;
        font-size: 14px;
        font-weight: 500;
        top: 23px;
        right: 20px;
        color: #999999;
    }

    input {
        font-size: 16px;
        color: #333;
        width: 100%;
    }

    .notify {
        position: absolute;
        font-size: 12px;
        color: #e74448;
        left: 33%;
        top: 43px;
    }

    .effect-7 {
        border: 1px solid #bbbbbb;
        padding: 20px 14px 20px;
        padding-left: 32.75862068965517%;
        transition: 0.4s;
        color: #333333;
    }


    .effect-7~.focus-border:before,
    .effect-7~.focus-border:after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 2px;
        background-color: #2fae8e;
        transition: 0.4s;
    }

    .effect-7~.focus-border:after {
        top: auto;
        bottom: 0;
    }

    .effect-7~.focus-border i:before,
    .effect-7~.focus-border i:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 2px;
        height: 0;
        background-color: #2fae8e;
        transition: 0.6s;
    }

    .effect-7~.focus-border i:after {
        left: auto;
        right: 0;
    }

    .effect-7:focus~.focus-border:before,
    .effect-7:focus~.focus-border:after {
        left: 0;
        width: 100%;
        transition: 0.4s;
    }

    .effect-7:focus~.focus-border i:before,
    .effect-7:focus~.focus-border i:after {
        top: 0;
        height: 100%;
        transition: 0.6s;
    }
}

@media all and (max-width: 768px) {
    .input-effect-border-box {
        .notify {
            font-size: 10px;
        }

        .input-label {
            font-size: 14px;
            top: 20px;
        }

        .before-label {
            font-size: 12px;
            top: 21px;
        }

        input {
            font-size: 14px;
        }
    }
}

@media all and (max-width: 500px) {
    .input-effect-border-box {
        .input-label {
            font-size: 12px;
            top: 21px;
        }

        .before-label {
            top: 20px;
        }

        input {
            font-size: 12px;
        }
    }
}