.Banner {
  height: 350px;
  background: #fcc500;
  position: relative;


  .banner-wrap {
      padding: 0 10px;
      margin: 0 auto;
      padding-top: 180px;

      .banner-text {
          font-size: 40px;
          font-weight: bold;
          color: #ffffff;
      }

      .banner-img-edu {
          position: absolute;
          top : 40%;
          right: 11%;
      }

      .banner-search {
          margin-top: 3.389830508474576%;
      }
  }
}

@media all and (min-width:1200px) {
  .Banner {
      .banner-wrap {
          width: 1180px;
      }
  }
}


@media all and (max-width:1199px) {
  .Banner {
      .banner-wrap {
          .banner-img {
              right: -14%;
          }
      }
  }
}

@media all and (max-width:1024px) {
  .Banner {
      .banner-wrap {
          .banner-text {
              font-size: 30px;
          }
      }
  }
}

@media all and (max-width:768px) {
  .Banner {
      height: 300px;
      text-align: center;

      .banner-wrap {
          .banner-text {
              font-size: 24px;
          }
      }
  }
}