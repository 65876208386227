.MyClassDetailTop {
    margin-top: 6rem;
    border: 1px solid #FCC500;
    border-radius: 10px;
    display: flex;

    .class-img {
        height: 190px;
        width: 16.66666666666667%;
    }

    .class-info {
        // width: 83.33333333333333%;
        width: 100%;

        .class-info-header-box {
            position: relative;
            background: #FCC500;
            border-radius: 6px 6px 0px 0px;

            .class-info-header {
                padding: 20px 30px 20px 30px;


                .class-name {
                    font-size: 24px;
                    color: #ffffff;
                    padding-left: 10px;
                }

                .class-grade {
                    font-size: 16px;
                    color: #ffffff;
                    padding-left: 10px;
                }

                .class-option {
                    display: inline-block;
                    position: absolute;
                    right: 30px;
                    cursor: pointer;

                    img {
                        display: block;
                    }
                }
            }
        }

        .class-detail-info {
            padding: 30px;

            .class-explain {
                font-size: 1rem;
                color: #333333;
                padding: 10px 0px 10px 0px;
                line-height: 1.5rem;

                .explain-quotes {
                    color: #f3ab19;
                }
            }

            .class-info-stroke {
                border: thin solid #0099FF;
                margin: 10px 0px 20px 0px;
            }

            .class-info-bottom {
                display: flex;
                font-size: 1rem;
                color: #333333;
                line-height: 3rem;
                position: relative;
                display: flex;
                flex-wrap: wrap;

                .class-teacher {
                    margin-left: 1rem;
                }

                .class-students {
                    margin-left: 1rem;
                }

                .class-assignment {
                    margin-left: 1rem;
                }

                .class-code {
                    margin-left: 1rem;
                }

                span {
                    margin-left: 10px;
                }

                img {
                    vertical-align: sub;
                }
            }

            .class-assignment-button-box {
                position: absolute;
                right: 0;

                .class-assignment-button {
                    text-align: center;
                    border: none;
                    background: #0099FF;
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    border-radius: 5px;
                    width: 200px;
                    cursor: pointer;
                    transition: all .2s linear;

                    &:hover {
                        background: #f3ab19;
                    }

                    img {
                        vertical-align: text-bottom;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media all and (max-width:768px) {
    .MyClassDetailTop {
        margin-top: 3rem;

        .class-info {
            .class-info-header-box {
                .class-info-header {
                    .class-name {
                        font-size: 1.3rem;
                    }
                }
            }

            .class-detail-info {
                .class-explain {
                    font-size: 0.8rem;
                }

                .class-info-bottom {
                    font-size: 0.8rem;
                }
            }
        }
    }

}