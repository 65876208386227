.MyClassList {
  margin-top: 150px;
  margin-bottom: 5rem;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .MyClassList {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    .create-enter-class {
      width: calc(100% - 1.75rem);
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;

      img {
        margin-top: 4rem;
        margin-bottom: 4rem;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }
}

@media (max-width: 500px) {
  .MyClassList {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .create-enter-class {
      img {
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.4rem;
      }
    }
  }
}
