.OrderItem {
  td {
    background: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 1rem;
    color: #333;
  }

  .order-seq {
    .order-detail-link {
      font-weight: 500;
      color: #333;
      .order-detail-text {
        font-size: 0.9rem;
        font-weight: 400;
      }
    }
  }

  .order-product {
    display: flex;
    align-items: center;
    img {
      width: 150px;
      height: 150px;
    }
    .order-product-name {
      word-break: keep-all;
    }
  }
}
