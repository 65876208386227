.MyStudentList {
  .my-student-list-title-wrap {
    width: 50%;
    margin: 0 auto;

    .my-student-list-title {
      font-weight: 500;
      background: #B56AFF;
      color: #fff;
      padding: 0.5rem 0.875rem;
      border-radius: 56px;
      text-align: center;
      font-size: 32px;
      margin: 1rem 0;
    }
  }

  .check-student-pwd {
    display: flex;
    input {
      flex: 1;
      outline: none;
      border: 1px solid #999999;
      border-radius: 4px;
      padding: 0.6rem;
      width: 100%;
      font-size: 1rem;
    }

    button {
      outline: none;
      border: none;
      color: #ffffff;
      border-radius: 4px;
      min-width: 4.5rem;
      background: #B56AFF;
      padding: 0.5rem;
      font-size: 1rem;
      cursor: pointer;
      margin-left: 0.3rem;
    }
  }

  .student-pwd-wrap {
    margin-top: 2rem;

    label {
      font-weight: 500;
    }
  }

  .my-student-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;

    .my-student-item {
      position: relative;
      width: calc(33.33% - 0.875rem);
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      padding: 1.5rem;
      margin: 1rem 0.4rem;
      cursor: pointer;
      border: 2.5px solid #B56AFF;

      .student-info {
        font-weight: 500;
        img {
          vertical-align: text-bottom;
          max-width: 24px;
        }
        .student-email-wrap {
          display: flex;
          .student-email {
            margin-right: 1rem;
            color: #0099FF;

            label {
              margin-left: 0.5rem;
            }
          }
          .student-email-value {
            flex: 1;
            text-align: right;
          }
        }
        .student-expire-date-wrap {
          display: flex;
          margin-top: 1rem;
          .student-expire-date {
            margin-right: 1rem;
            color: #B56AFF;

            label {
              margin-left: 0.5rem;
            }
          }
          .student-expire-date-value {
            flex: 1;
            text-align: right;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .MyStudentList {
    .my-student-list {
      .my-student-item {
        width: calc(50% - 0.875rem);
      }
    }
  }
}
@media all and (max-width: 768px) {
  .MyStudentList {
    .my-student-list-title-wrap {
      width: 100%;
      .my-student-list-title {
        padding: 0.5rem;
        font-size: 24px;
      }
    }
    .my-student-list {
      .my-student-item {
        width: calc(100% - 0.875rem);
      }
    }
  }
}
