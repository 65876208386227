.LicenseCode {
  margin-bottom: 3rem;
  .license-code-title {
    display: inline-block;
    font-size: 30px;
    color: #999999;
    font-weight: 500;
    margin-bottom: 1.25rem;
    color: #fcc500;
  }

  .license-code-input-wrap {
    position: relative;
    display: flex;
    width: 45%;
    input {
      flex: 1 1;
      border: none;
      outline: none;
      padding-left: 1rem;
      border: 1px solid #eee;
      border-radius: 6px 0 0 6px;
      box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.15) inset;
      font-size: 1rem;
    }
    .license-code-use-btn {
      background-color: #fcc500;
      cursor: pointer;
      border-radius: 0px 6px 6px 0px;
      padding: 0.6rem 0.8rem;
      box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.15) inset;
    }
  }
}

@media all and (max-width: 768px) {
  .LicenseCode {
    .license-code-title {
      font-size: 18px;
    }
    .license-code-input-wrap {
      width: 100%;
      padding: 0 2rem;
    }
  }
}
