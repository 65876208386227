
.SchoolDownload {
  .download-headline {
    margin-top: 80px;
    padding-bottom: 10px;

    p {
      font-size: 30px;
      font-weight: 800;
      color: #000000;
    }
  }

  .school-download-wrap {
    border-top: 2px solid #FCC500;
    padding-top: 2rem;
    display: flex;

    .school-download-pc {
      width: 50%;

      .school-download-pc-button {
        button {
          width: 100%;
          border: none;
          background: #7EE716;
          border-radius: 10px;
          font-size: 24px;
          font-weight: bold;
          text-align: left;
          color: #ffffff;
          height: 100px;
          padding: 0 5%;
          cursor: pointer;

          &:hover {
            background: #7EE716;
          }

          img {
            vertical-align: bottom;
            float: right;
          }
        }
      }

      .school-download-pc-label {
        margin-top: 5px;
        font-size: 16px;
        color: #737373;
      }
    }

    .school-download-hmd {
      width: 50%;
      padding-left: 10px;

      .school-download-hmd-button {
        button {
          width: 100%;
          border: none;
          background: #FF9900;
          border-radius: 10px;
          font-size: 24px;
          font-weight: bold;
          text-align: left;
          color: #ffffff;
          height: 100px;
          padding: 0 5%;
          cursor: pointer;

          &:hover {
            background: #FF9900;
          }

          img {
            vertical-align: bottom;
            float: right;
          }
        }
      }

      .school-download-hmd-label {
        margin-top: 5px;
        font-size: 16px;
        color: #737373;
      }
    }


    .school-download-ios {
      width: 50%;
      padding-left: 10px;

      .school-download-ios-button {
        button {
          width: 100%;
          border: none;
          background: #FCC500;
          border-radius: 10px;
          font-size: 24px;
          font-weight: bold;
          text-align: left;
          color: #ffffff;
          height: 100px;
          padding: 0 5%;
          cursor: pointer;

          &:hover {
            background: #FCC500;
          }

          img {
            vertical-align: bottom;
            float: right;
          }
        }
      }

      .school-download-hmd-label {
        margin-top: 5px;
        font-size: 16px;
        color: #737373;
      }
    }

  }
}

@media all and (max-width: 1024px) {
  .SchoolDownload {
    .school-download-wrap {
      flex-direction: column;

      .school-download-pc {
        width: 100%;
        padding: 0 10px;
      }

      .school-download-tablet {
        width: 100%;
        padding: 0 10px;
      }

      .school-download-hmd {
        width: 100%;
        padding: 0 10px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .SchoolDownload {
    .download-headline {
      p {
        font-size: 24px;
        text-align: center;
      }
    }

    .school-download-wrap {
      flex-direction: column;

      .school-download-pc {
        width: 100%;
        padding: 0 10px;

        .school-download-pc-label {
          font-size: 12px;
        }

        .school-download-pc-button {
          button {
            font-size: 20px;
            height: 80px;

            img {
              width: 30px;
            }
          }
        }
      }

      .school-download-tablet {
        width: 100%;
        padding: 0 10px;
        margin-top: 3%;

        .school-download-tablet-label {
          font-size: 12px;
        }

        .school-download-tablet-button {
          button {
            font-size: 20px;
            height: 80px;

            img {
              width: 30px;
            }
          }
        }
      }

      .school-download-hmd {
        width: 100%;
        padding: 0 10px;
        margin-top: 3%;

        .school-download-hmd-label {
          font-size: 12px;
        }

        .school-download-hmd-button {
          button {
            font-size: 20px;
            height: 80px;

            img {
              width: 30px;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .SchoolDownload {
    .download-headline {
      p {
        font-size: 20px;
      }
    }

    .school-download-wrap {
      .school-download-pc {
        .school-download-pc-button {
          button {
            font-size: 16px;

            img {
              width: 25px;
            }
          }
        }
      }

      .school-download-tablet {
        .school-download-tablet-button {
          button {
            font-size: 16px;

            img {
              width: 25px;
            }
          }
        }
      }

      .school-download-hmd {
        .school-download-hmd-button {
          button {
            font-size: 16px;

            img {
              width: 25px;
            }
          }
        }
      }
    }
  }
}
