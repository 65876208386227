.NoStudents {
    margin-top: 30px;
    text-align: center;
    margin-bottom: 500px;

    p {
        font-size: 20px;
        color: #999999;
        font-weight: 400;
    }

    button {
        margin-top: 20px;
        background: #f3ab19;
        width: 220px;
        height: 50px;
        border-radius: 5px;
        font-size: 16px;
        color: #fff;
        border: none;
        cursor: pointer;

        &:hover {
            background: #e7960a;
        }
    }
}