.LectureVideoItem {
  width: calc(33.33% - 0.875rem);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin: 1rem 0.4rem;
  cursor: pointer;

  .lecture-thumb {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 50%;
    border-radius: 20px 20px 0 0;
  }
  .lecture-bottom {
    position: relative;
    padding: 1rem;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 7rem;

    .lecture-period {
      position: absolute;
      top: -28px;
      left: 12px;
      display: inline-block;
      color: #333;
      background: #f3ab19;
      padding: 0.5rem;
      border-radius: 50px;
      font-weight: 700;
    }

    .lecture-bottom-left {
      font-size: 1rem;
      word-break: keep-all;
    }
    .lecture-bottom-right {
      font-size: 1rem;
      margin-top: 0.3rem;
      word-break: keep-all;
    }
  }
}

@media all and (max-width: 1200px) {
  .LectureVideoItem {
    width: calc(50% - 0.875rem);
  }
}
@media all and (max-width: 768px) {
  .LectureVideoItem {
    width: calc(100% - 0.875rem);
  }
}
