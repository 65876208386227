.IntroMiddle {
  width: 1280px;
  margin : 40rem auto 0 auto;

  .intro-middle-title-wrap {
    text-align: center;
    margin: 6rem 0;

    h2 {
      font-size: 33px;
      font-weight: 800;
      color: #000;
    }
    p {
      margin-top: 2rem;
      margin-bottom: 4rem;
      font-size: 22px;
      font-weight: 400;
      color: #494645;
      word-break: keep-all;
      padding: 0 10rem;
    }
  }

  .intro-middle-contents-wrap {
    font-size: 20px;
    font-weight: 800;

    .intro-content-list {
      display: flex;
      flex-wrap: wrap;

      .intro-content-item {
        width: calc(33.333% - 1rem);
        margin-top: 4rem;
        margin-left: 0.7em;    
        text-align: center;

        .intro-content-img-box {
          img {
            max-width: 100%;
          }
          p{
            font-size: 20px;
            color: #000000;
            font-weight:500;
            margin-top: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .IntroBottom {
    .intro-bottom-title-wrap {
      p {
        padding: 0 2rem;
      }
    }
    .intro-bottom-contents-wrap {
      .intro-content-list {
        .intro-content-item {
          width: calc(50% - 2rem);
          margin: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .IntroBottom {
    .intro-bottom-title-wrap {
      h2 {
        font-size: 28px;
        word-break: keep-all;
      }
      p {
        font-size: 18px;
        padding: 0 0.5rem;
      }
    }
    .intro-bottom-contents-wrap {
      .intro-content-list {
        .intro-content-item {
          width: calc(100% - 1rem);
          margin: 0.5rem;
        }
      }
    }
  }
}
