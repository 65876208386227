.SchoolDownloadSpec {
  margin-bottom: 150px;
  margin-top: 5%;

  .download-headline-pc {
    margin-top: 80px;
    padding-bottom: 30px;

    p {
      font-size: 30px;
      font-weight: 500;
      color: #000000;
      line-height: 100%;
    }
  }

  .download-spec-table-wrap {
    .download-spec-table-pc {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;

      tr {
        text-align: center;
        color: #000000;
        font-size: 20px;

        .download-col-first-pcs {
          padding-left: 8%;
          padding-right: 1rem;
          text-align: left;
          font-weight: 700;
          height: 80px;
          width: 30%;
        }

        .download-col-first-pc {
          padding-left: 8%;
          padding-right: 1rem;
          text-align: left;
          font-weight: 400;
          height: 80px;
          width: 30%;
        }

        .download-col-rest-pc {
          font-weight: 400;
          width: 360px;
          height: 80px;
        }

        // td:first-child {
        //     padding-left: 50px;
        //     text-align: left;
        //     font-weight: 500;
        // }

        // td {
        //     width: 360px;
        //     height: 80px;
        // }
      }

      .download-spec-head-pc {
        background: rgba(126, 231, 22, 0.1);
        border-bottom: 2px solid #7EE716;
        color: #7EE716;
        font-size: 25px;
        font-weight: 700;
      }

      .download-spec-info-pc {
        background: #ffffff;
        color: #000000;
        font-size: 20px;
        border-bottom: 2px solid #7EE716;
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .SchoolDownloadSpec {
    .download-spec-table-wrap {
      .download-spec-table-pc {
        .download-spec-head-pc {
          font-size: 20px;
        }

        .download-spec-info-pc {
          font-size: 1rem;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .SchoolDownloadSpec {
    .download-headline-pc {
      p {
        font-size: 24px;
        text-align: center;
      }
    }

    .download-spec-table-wrap {
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;

      .download-col-first-pcs{
        width: auto;
        font-family: 700px;
        tr {
          
          .download-col-rest-pc {
            width: 270px;
            text-align: left;
            padding-left: 1rem;
          }
        }

        .download-spec-head-pc {
          font-size: 1rem;
        }

      }

      .download-spec-table-pc {
        width: auto;
 
        tr {
          
          .download-col-rest-pc {
            width: 270px;
            text-align: left;
            padding-left: 1rem;
          }
        }

        .download-spec-head-pc {
          font-size: 1rem;
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .SchoolDownloadSpec {
    .download-headline-pc {
      p {
        font-size: 20px;
      }
    }
    .download-spec-table-wrap {


      .download-spec-table-pcs {
        .download-spec-head-pc {
          font-size: 0.9rem;
        }

        .download-spec-info-pc {
          font-size: 0.9rem;
        }
      }

      .download-spec-table-pcs {
        tr {
          .download-col-rest-pc {
            width: 160px;
          }
        }
      }


      .download-spec-table-pc {
        .download-spec-head-pc {
          font-size: 0.9rem;
        }

        .download-spec-info-pc {
          font-size: 0.9rem;
        }
      }

      .download-spec-table-pc {
        tr {
          .download-col-rest-pc {
            width: 160px;
          }
        }
      }
    }
  }
}
