.MyPageTop {
    background: #FCC500;
    height: 350px;

    .user-info {
        margin: 0 auto;
        padding: 0 10px;
        height: 100%;
        padding-top: 170px;
        position: relative;
        font-size: 36px;
        color: #ffffff;

    }

    .user-option {
        display: inline-block;
        margin-left: 10px;
        width: 80px;
        line-height: 30px;
        vertical-align: middle;

        .user-update {
            text-align: center;
            font-size: 16px;
            color: #ffffff;
            border: 1px solid #ffffff;
            border-radius: 15px;

            img {
                margin-left: 4px;
                margin-bottom: 2px;
                vertical-align: middle;
            }
        }
    }

    .mypage-top-right {
        img {
            position: absolute;
            right: 0%;
            bottom: 0;
        }
    }
}

@media all and (min-width:1200px) {
    .MyPageTop {
        .user-info {
            width: 1180px;
        }
    }
}

@media all and (max-width:768px) {
    .MyPageTop {
        height: 300px;

        .user-info {
            text-align: center;
            font-size: 30px;

        }

        .user-option {
            .user-update {
                font-size: 13px;
            }
        }

        .mypage-top-right {
            display: none;
        }
    }
}

@media all and (max-width:500px) {
    .MyPageTop {
        .user-info {
            font-size: 24px;
        }
    }
}