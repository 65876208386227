.headroom {
  background: #FCC500;
  z-index: 9999 !important;
}

.headroom--pinned {
  // background: #2fae8e;
  box-shadow: 0px -16px 56px -4px #333;
}



.Header {
  height: 120px;
  width: 100%;

  .header-wrap {
    height: 100%;
    padding: 10px;
    margin: 0 auto;
    line-height: 96px;

  }

  .header-select{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
  
  ul{
    margin: 0;
    padding: 0;
}

.select {
  display: inline-block;
  width: 120px;
  border: 1px solid #999;
  ul {
    list-style-type: none;
    padding-left: 0px;
  }
  
  .select {
    display: inline-block;
    width: 120px;
    border: 1px solid #999;
  }
  
  .select .selected {
    display: flex;
    justify-content: space-between;
    padding: 8px 5px;
  }
  
  .select .selected .selected-value {
    max-width: 90px;
  }
  
  .select .selected .arrow {
    width: 24px;
    background: url("https://uxwing.com/wp-content/themes/uxwing/download/02-arrow-direction/arrow-bottom.png") no-repeat 70% 50%;
    background-size: 50% 50%;
  }
  
  .select ul li,
  .select .selected .selected-value {
    white-space: nowrap;
    /* 줄바꿈 안함 */
    overflow: hidden;
    text-overflow: ellipsis;
    /* 말줄임 적용 */
  }
  
  .select ul {
    width: 120px;
    border: 1px solid #999;
    display: none;
    position: absolute;
    background: #fff;
    border-top: none;
    margin: 1px 0 0 -1px;
    cursor: pointer;
  }
  
  .select.active ul {
    display: initial;
  }
  
  .select ul li {
    padding: 8px 5px;
  }
  
  .select ul li:hover {
    background: rgba(168, 156, 235, 0.35)
  }
}

option{
  border-radius: 10px;
}
  .logo {
    float: left;

    img {
      vertical-align: middle;
      cursor: pointer;
      max-width: 100%;
    }
  }

  .nav {
    li {
      float: left;
      margin-left: 45px;
      font-size: 16px;
      font-weight: bold;

      label {
        cursor: pointer;
        color: #ffffff;
        font-size: 16px;
      }
    }
  }

  
}


@media all and (min-width: 1200px) {
  .header-wrap {
    width: 1180px;
  }
}

@media all and (max-width: 1200px) {
  .Header {
    height: 75px;

    .header-wrap {
      padding: 0 2.666666666666667em;
      line-height: 75px;
    }

    .logo {
      img {
        width: 80%;
      }
    }

    .nav {
      display: none;
    }

    .HeaderRight {
      display: none;
    }
  }
}
