.EduCaseListAR {
  max-width: 1180px;
  margin: 0 auto;
  margin-bottom: 4rem;
  margin-top: 4rem;
  text-align: center;

  .edu-case-category-list {
    margin: 3rem 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    label{
      margin-top: 1rem;
      font-size: 20px;
      font-weight: 500;
      position: absolute;
      color:#fcc500;
    }


    button {
      border: none;
      padding: 0.475rem 0.7rem;
      margin: 0.3rem;
      cursor: pointer;
      background-color: transparent;
      color: transparent;
      font-size: 24px;
      font-weight: 500;
      width: 125px;
    }
    .category-active {
      border-bottom: 5px solid #fcc500;
      color: transparent;
    }
  }

  .edu-case-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    img{
      width: 100%;
    }
  }

  .pagination {
    display: flex;
    margin-top: 2rem;
    text-align: center;
    flex-direction: row;
    justify-content: center;


    li {
      color: #010000;
      display: flex;
      font-size: 16px;
      font-weight: 400;
      width: 30px;
      line-height: 30px;
      margin-right: 5px;
      margin-left: 5px;
      border: none;
      border-radius: 20px;
      cursor: pointer;
      -ms-user-select: none;
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      outline: none;

      a {
        width: 30px;
        outline: none;
        display: block;
        margin-left: 2px;
      }

      &:hover {
        transition: all 0.2s linear;
        background: rgba(0,0,0,0.5);
        color: #ffffff
      }
    }
   

    



    .rc-pagination-jump-prev{
        order: 1;
    }

    .rc-pagination-prev{
      order: 2;
    }
    .rc-pagination-item {
      order: 3;
    }

    .rc-pagination-next{
      margin-left: 20px;
      order: 4;
    }
    .rc-pagination-jump-next{
        order: 5;
    }

    .rc-pagination-jump-prev,
    .rc-pagination-jump-next{
      
      color :#010000;
    }

    .rc-pagination-item-active {
      transition: all 0.2s linear;
      background: #fcc500;
      border-radius: 20px;
      color: #ffffff;
    }
  }
}

@media all and (max-width: 500px) {
  .EduCaseList {
    .edu-case-category-list {
      button {
        word-break: keep-all;
        font-size: 0.8rem;
        padding: 0.475rem 0.4rem;
        border-radius: 12px;
      }
    }
  }
}
