.MyClassItem {
    width: calc(50% - 1.75rem);
    border: 1px solid #FCC500;
    border-radius: 12px;
    margin: 9px;
    display: inline-block;

    .class-header {
        width: 100%;
        background: #FCC500;
        border-radius: 10px 10px 0px 0px;
        line-height: 90px;
        position: relative;
        padding-left: 7.758620689655172%;

        .class-name {
            font-size: 20px;
            font-weight: 500;
            color: #ffffff;
            display: inline-block;
        }

        .class-option {
            position: absolute;
            right: 7.758620689655172%;
            bottom: -8px;
            cursor: pointer;
        }
    }

    .class-info {
        padding: 2.5rem;
        font-size: 1rem;
        color: #737373;
        display: flex;

        .class-info-1 {
            display: inline-block;

            img {
                vertical-align: sub;
                padding-right: 10px;
            }

            .class-grade {
                padding-top: 30px;
            }

        }

        .class-info-2 {
            display: inline-block;
            margin-left: auto;

            img {
                vertical-align: sub;
                padding-right: 10px;
            }

            .class-code {
                padding-top: 30px;
            }
        }
    }

    .class-enter-button {
        text-align: center;
        padding-bottom: 25px;

        button {
            border: none;
            background: #f3ab19;
            border-radius: 25px;
            font-weight: 500;
            color: #ffffff;
            width: 44.82758620689655%;
            height: 50px;
            font-size: 20px;
            cursor: pointer;

            &:hover {
                background: #e7960a;
            }
        }
    }
}



@media (max-width: 1024px) {
    .MyClassItem {
        .class-info {
            font-size: .9rem;
            padding: 2rem;
        }
    }
}

@media (max-width: 768px) {
    .MyClassItem {
        width: calc(100% - 1.75rem);
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;

        .class-info {
            font-size: .8rem;
            padding: 1.8rem;
        }
    }
}

@media (max-width: 500px) {
    .MyClassItem {

        .class-header {
            line-height: 60px;
            padding-left: 1rem;

            .class-name {
                font-size: 1rem;
            }
        }

        .class-info {
            padding: 1.5rem;
        }

        .class-enter-button {
            margin-top: 1rem;

            button {
                width: 10rem;
                font-size: 1rem;
            }
        }
    }
}