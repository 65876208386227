.OrderList {
  display: block;
  margin-bottom: 2rem;
  .order-list-table {
    width: 100%;
    text-align: center;
    border-collapse: separate;
    border-spacing: 4px;
    thead {
      tr {
        th {
          font-size: 1.1rem;
          font-weight: 400;
          background: #FCC500;
          border-radius: 6px;
          color: #fff;
          padding: 1.25rem;
        }
      }
    }
  }

  .pagination {
    margin-top: 6.355932203389831%;
    display: flex;
    margin-top: 2rem;
    text-align: center;
    flex-direction: row;
    justify-content: center;

    li {
      display: flex;
      font-size: 16px;
      font-weight: 400;
      width: 30px;
      line-height: 30px;
      margin-right: 5px;
      margin-left: 5px;
      border: none;
      border-radius: 20px;
      cursor: pointer;
      -ms-user-select: none;
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      outline: none;

      a {
        width: 30px;
        outline: none;
        display: block;
        margin-left: 2px;
      }

      &:hover {
        transition: all 0.2s linear;
        background: rgb(0,0,0,0.5);;
        color: #ffffff;
      }
    }

  .rc-pagination-jump-prev{
      order: 1;
  }

  .rc-pagination-prev{
    order: 2;
  }
  .rc-pagination-item {
    order: 3;
  }

  .rc-pagination-next{
    margin-left: 20px;
    order: 4;
  }
  .rc-pagination-jump-next{
      order: 5;
  }

    .rc-pagination-item-active {
      transition: all 0.2s linear;
      background: #B56AFF;
      border-radius: 20px;
      color: #ffffff;
    }
  }
}

.OrderListNotFound {
  font-size: 1.25rem;
  text-align: center;
  margin: 10rem 0;
}

@media all and (max-width: 1024px) {
  .OrderList {
    display: none;
  }
}
