.EduCaseItem {
  position: relative;
  width: calc(33.33% - 0.875rem);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin: 1rem 0.4rem;
  cursor: pointer;

  .educase-thumb {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 70%;
    border-radius: 20px;
  }

  .educase-info {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 0px 0px 20px 20px;
    align-items: baseline;

    .educate-title {
      font-size: 1rem;
    }

    .educase-date {
      flex: 1;
      text-align: right;
      color: rgba($color: #fff, $alpha: 0.85);
      font-size: 0.875rem;
    }
  }
}

@media all and (max-width: 1200px) {
  .EduCaseItem {
    width: calc(50% - 0.875rem);
  }
}
@media all and (max-width: 768px) {
  .EduCaseItem {
    width: calc(100% - 0.875rem);
  }
}
