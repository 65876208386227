.footer-wrap {
  position: relative;
  background: #f8f8f8;
  min-width: 200px;
  padding: 3rem 0 3rem 0;

  .footer-box {
    height: 100%;
    margin: 0 auto;
    display: flex;

    .footer-hr{
      border: 1px solid #DDDDDD;
      }

    .gp-logo {
      width: 20%;
      text-align: center;
      padding: 50px 0px;
    }

    .footer-center {
      width: 55%;

      .footer-center-text {
        padding: 0 4rem;
      }

      .footer-center-top {
        font-size: 14px;
        font-weight: 400;
        display: flex;
        margin-bottom: 25px;

        p {
          margin-right: 20px;
          color: #737373;
        }
      
        .footer-center-p {
          color : #000000
        }
      }

      .footer-center-bottom {
        font-size: 11px;
        color: #737373;
        font-weight: 300;

        div {
          display: flex;

          a:link{
            color: #737373;
          }
          p {
            margin-right: 10px;
          }
        }

        .copyrights {
          font-weight: 200;
          margin-top: 25px;
        }
      }
    }

    .footer-right {
      text-align: right;
      padding-left: 30px;
      width: 30%;

      .footer-cs {
        font-size: 14px;
        font-weight: 400;
        color: #737373;
      }

      .footer-company-ph {
        font-size: 20px;
        font-weight: 500;
        color: #737373;
      }

      .footer-company-wh {
        font-size: 11px;
        color: #737373;
      }

      .footer-company-sns {
        float: right;
        margin-top: 15px;
        margin-right: -10px;

        ul {
          display: flex;

          li {
            margin-right: 10px;
          }
        }

      }
    }
  }

  .footer-bottom-sns {
    padding: 3rem 0;
    display: none;

    ul {
      padding: 0 2rem;
      display: flex;
      float: right;

      li {
        padding: 0 0.3em;
      }
    }
  }
}

.none {
  display: none;
}

@media all and (min-width:1200px) {
  .footer-box {
    width: 1180px;
    padding: 0 10px;
  }
}

@media all and (max-width: 768px) {
  .footer-wrap {
    .footer-box {
      padding-left: 2rem;
      padding-right: 2rem;

      .footer-center {
        width: 100%;
        padding-bottom: 2rem;
        border-bottom: 1px solid #bbb;

        .footer-center-bottom {
          font-size: 10px;
        }

        .footer-center-top {
          font-size: 1rem;
        }

        .footer-center-text {
          padding: 0px;
        }
      }

      .gp-logo {
        display: none;
      }

      .footer-right {
        display: none;
      }
    }

    .footer-bottom-sns {
      display: block;
    }
  }
}

@media all and (max-width: 500px) {}