.MyPageList {
  margin-bottom: 50px;
  margin-top: 50px;

  .mypage-list-select {
    display: flex;
    margin-bottom: 50px;

    .mypage-select {
      margin-right: 2.5%;
      font-size: 30px;
      color: #999999;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        color: #FCC500;
        border-bottom: 3px solid #f3ab19;
      }
    }

    .select-active {
      color: #FCC500;
      border-bottom: 3px solid #f3ab19;
    }
  }
}

@media all and (max-width: 1024px) {
  .MyPageList {
    text-align: center;

    .mypage-list-select {
      display: block;

      .mypage-select {
        font-size: 22px;
        display: inline-block;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .MyPageList {
    .mypage-list-select {
      .mypage-select {
        font-size: 18px;
      }
    }
  }
}
