.HeaderMobileMenu {
  float: right;
  top: 0;
  // scrolltop: false;
  display: none;

  .menu-trigger {
    width: 30px;
    height: 30px;
    display: inline-block;
    position: fixed;
    z-index: 10;
    top: 45px;
    right: 32px;
    cursor: pointer;
  }

  /* These are the icon lines */

  .menu-trigger span {
    position: absolute;
    display: inline-block;
    height: 2px;
    width: 100%;
    background: #ffffff;
    transition: all 0.3s linear;
  }

  .menu-trigger span:nth-child(1) {
    top: 0px;
  }

  /* Line 2 and three are sitting on each other so we can animate an X from the center */

  .menu-trigger span:nth-child(2),
  .menu-trigger span:nth-child(3) {
    top: 8px;
  }

  .menu-trigger span:nth-child(4) {
    top: 16px;
  }

  /* This is the X Icon */

  .icon-active span:nth-child(1),
  .icon-active span:nth-child(4) {
    opacity: 0;
  }

  /* Second line rotating 45 Deg */
  .icon-active span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* Third line rotating -45 Deg */
  .icon-active span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .links {
    color: white;
    top: 100%;
    left: 0;
    right: 0;
    position: absolute;
    text-decoration: none;
    list-style: none;
    height: 0;
    overflow-y: scroll;
    -ms-overflow-style: none;
    background: #FFCC00;
    width: 100%;
    /* border-bottom: 4px solid white; */
    text-align: center;
    opacity: 0;
    // -webkit-transition: all .3s linear 0s;
    // transition: all .3s linear 0s;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .links ul li {
    list-style: none;
    margin: 0.6rem;
    // padding-top: 2em;
    font-size: 1.3rem;
    line-height: 75px;
  }

  .links li:nth-child(1) {
    padding-top: 0;
  }

  .links a {
    text-decoration: none;
    color: white;
  }

  .toggle {
    height: 525px;
    opacity: 1;
  }

  .cotton {
    position: fixed;
    height: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    z-index: -1;
  }

  .cotton-toggle {
    height: 100vh;
  }
}

@media all and (max-width: 1200px) {
  .HeaderMobileMenu {
    display: block;

    .menu-trigger {
      top: 30px;
    }
  }
}

@media all and (max-width: 500px) {
  .HeaderMobileMenu {
    .links ul li {
      margin: 0;
      font-size: 1.1rem;
    }
  }
}
