.MyLicenseList {
  display: inline-block;
  width: 66%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: left;
  position: relative;
  margin-bottom: 5rem;

  .my-license-name {
    font-size: 30px;
    color: #ffffff;
    background: #FCC500;
    padding: 20px 30px 20px 30px;
    font-weight: 500;
    border-radius: 10px 10px 0px 0px;
  }

  .my-license-info {
    padding: 20px 30px 20px 30px;

    .current-license-state {
      font-size: 24px;
      font-weight: 500;
      color: #333333;
    }

    .license-purchase,
    .license-expire,
    .license-price {
      font-size: 20px;
      color: #333333;
      margin-top: 15px;

      span {
        text-align: left;
      }
    }
  }

  .license-term {
    font-size: 14px;
    color: #FCC500;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    margin-top: 20px;
  }

  .license-in-use {
    color: #FCC500;
  }

  .license-expired {
    color: #e74448;
  }
}

@media all and (max-width: 1024px) {
  .MyLicenseList {
    .my-license-name {
      font-size: 24px;
    }

    .my-license-info {
      .current-license-state {
        font-size: 20px;
      }

      .license-purchase {
        font-size: 16px;
      }

      .license-expire {
        font-size: 16px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .MyLicenseList {
    width: 85%;

    .my-license-name {
      font-size: 22px;
    }

    .my-license-info {
      .current-license-state {
        font-size: 18px;
      }

      .license-purchase {
        font-size: 14px;
      }

      .license-expire {
        font-size: 14px;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .MyLicenseList {
    width: 90%;

    .my-license-name {
      font-size: 18px;
    }

    .my-license-info {
      .current-license-state {
        font-size: 15px;
      }

      .license-purchase {
        font-size: 13px;
      }

      .license-expire {
        font-size: 13px;
      }
    }
  }
}
