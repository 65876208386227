.CreateOrEnterClass {
  width: calc(50% - 1.75rem);
  border-radius: 25px;
  border: 1.5px solid #FCC500;
  text-align: center;
  cursor: pointer;
  margin: 10px 10px 10px 10px;
  display: inline-block;

  img {
    margin-top: 95px;
    margin-bottom: 25px;
  }

  p {
    font-size: 24px;
    color: rgba($color: #000000, $alpha: 0.5);
    font-weight: 500;
    padding-bottom: 60px;
  }
}
