.StudentItem {
    border: 1px solid #FCC500;
    border-radius: 10px;
    width: calc(50% - 1.75rem);
    margin: .8rem;
    font-size: 1rem;
    position: relative;

    .student-info-wrap {
        padding: 1.5rem;
        display: flex;

        .student-title {
            color: #f3ab19;
            margin-top: .5rem;
            margin-bottom: .5rem;
        }

        .student-value {
            color: #333333;
            margin-left: 1.5rem;
            margin-top: .5rem;
            margin-bottom: .5rem;
        }

        .student-option {
            padding-top: 1rem;
            position: absolute;
            right: 1rem;

            img {
                vertical-align: bottom;
                margin-left: 20px;
                cursor: pointer;
            }
        }

    }



}

@media all and (max-width:1024px) {
    .StudentItem {
        width: calc(100% - 1.75rem);
    }
}

@media all and (max-width:768px) {
    .StudentItem {
        font-size: 0.8rem;
    }
}