.main-button{

    margin-top: 10rem;
    margin-right: -10rem;

    button{
    font-size: 12px;
    font-weight: bold;
    background-color: #FFCB00;
    color: #ffffff;
    width: 85px;
    height: 85px;
    border: 4px solid #ffffff;
    border-radius: 75px;
    &:hover{
        cursor:pointer;
    }
    }

    .main-free{
        p{
            font-size: 14px;
            color:#ED3C30;
        }
    }
    .main-vrware{
        margin-top : 15px;
    }

    .main-school{
        margin-top : 15px;
    }
}